import React, { ReactNode, useState } from 'react';
import { isMobile } from 'react-device-detect';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Color } from '@material-ui/lab';

import Footer from '../components/Footer/Footer';
import AddEditPageDetail from './Editor/AddEditPageDetail';
import AppContext from './AppContext';
import { SnackbarContext } from './Snackbar/Context';
import { NiwSnackbar } from './Snackbar/Snackbar';
import { RouterEvents, SnackBarSeverity } from '../utils/enums';
import { initFalse } from '../utils/constants';
import { externalServerUrl } from '../server/constants';
import Loader from './Loader';
import { sleep } from '../utils/commonUtils';


const Header = dynamic(() => import('../components/Header/Header'), {ssr: false});
const defaultDescription: string = 'NIW Companies specializes in innovative estate, business, and retirement planning solutions for high net worth professionals.  Our team combines extensive industry expertise and alternative thinking to offer financial solutions that are high quality, durable, and cost effective';
const defaultTitle: string = 'NIW Companies';

type Props = {
  title?: string,
  description?: any,
  children?: ReactNode,
  dispatch?: Function,
  emptyFindStrategyName?: Function,
  isIntroVideoComplete?: boolean
  indexPage?: boolean,
  keyword?: string,
  pageDescription?: string,
  homePage?: boolean
}

const Layout = (layoutProps: Props) => {
  const {
    children,
    description,
    dispatch,
    emptyFindStrategyName,
    homePage,
    indexPage,
    isIntroVideoComplete,
    keyword,
    pageDescription,
    title
  } = layoutProps;
  const router = useRouter();
  const containerRef = React.createRef<any>();
  const [open, setOpen] = React.useState<boolean>(false);
  const appContext: any = React.useContext(AppContext);
  let metaDescription: string = defaultDescription;

  const requestFullScreen = () => {
    if (isMobile) {
      window.scrollTo(0, 1);
      // const doc = window.document.getElementsByTagName('html')[ 0 ];
      // // @ts-ignore
      // doc.mozRequestFullScreen && doc.mozRequestFullScreen();
      // // @ts-ignore
      // doc.msRequestFullscreen && doc.msRequestFullscreen();
      // doc.requestFullscreen && doc.requestFullscreen(); // standard
    }
  };

  const scrollTop = () => {
    containerRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
  };

  React.useEffect(() => {
    let unmounted = false;
    if (unmounted) {
      return;
    }

    setTimeout(function () {
      // This hides the address bar:
      if (isMobile)
        window.scrollTo(0, 1);
    }, 0);

    scrollTop();

    requestFullScreen(); // NIWC-1906 URL closing example

    return () => {
      unmounted = true;
    };
  }, []);

  if (description && description.blocks) {
    if (description && description.blocks.length > 0) {
      metaDescription = description.blocks[ 0 ].text || defaultDescription;
    }
  } else {
    metaDescription = description || defaultDescription;
  }

  const [snackbarOpen, setSnackbarOpen] = React.useState(initFalse);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState<Color>(SnackBarSeverity.Success);
  const showMessage = (message: string, severity: Color = SnackBarSeverity.Success) => {
    setSnackbarOpen(!initFalse);
    setSnackMessage(message);
    setSnackSeverity(severity);
  };
  const snackBarProps = {
    handleClose: (_event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbarOpen(initFalse);
      showMessage('', SnackBarSeverity.Success);
    },
    message: snackMessage,
    open: snackbarOpen,
    severity: snackSeverity,
  };
  const snackBarProviderProps = {message: snackMessage, open: snackbarOpen, showMessage};
  const [loading, setLoading] = useState(initFalse);
  React.useEffect(() => {
    router.events.on(RouterEvents.RouteChangeStart, () => setLoading(!initFalse));
    router.events.on(RouterEvents.RouteChangeComplete, () => setLoading(initFalse));
    router.events.on(RouterEvents.RouteChangeError, () => setLoading(initFalse));
    sleep().then(scrollTop);
    return () => {
      router.events.off(RouterEvents.RouteChangeStart, () => setLoading(!initFalse));
      router.events.off(RouterEvents.RouteChangeComplete, () => setLoading(initFalse));
      router.events.off(RouterEvents.RouteChangeError, () => setLoading(initFalse));
    };
  }, [router.events]);

  return (
    <SnackbarContext.Provider value={snackBarProviderProps}>
      <Head>
        <title>{title || defaultTitle}</title>
        <meta name="description" content={pageDescription || metaDescription.substring(0, 125)}/>
        <meta name="keywords" content={keyword}/>
        <meta property="og:type" content={externalServerUrl + '/'}/>
        <meta property="og:title" content={title || defaultTitle}/>
        <meta property="og:description" content={pageDescription || metaDescription.substring(0, 125)}/>
        <meta property="og:url" content={externalServerUrl + router.pathname}/>
        <meta
          property="og:image"
          content="https://www.niwcorp.com/_next/static/images/niw-logo-fbccae8883579a0d62c99f5e86ce2b93.png"
        />
        <meta property="og:image:width" content="880"/>
        <meta property="og:image:height" content="660"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:site_name" content="NIW CORP"/>
        {
          homePage &&
          <meta name="google-site-verification" content="hkD9GoyB-Jp67dtO3DsreJGO59Bg-g3JGMcRaK2Ufm8"/>
        }
      </Head>

      <Header resetFindYourStrategy={dispatch} emptyFindStrategyName={emptyFindStrategyName} scollTop={scrollTop}/>

      <div style={{float: 'left', width: '100%'}} ref={containerRef}>

        {
          loading &&
            <Loader />
          }

        {children}

        {
          open && (
          <AddEditPageDetail
          editPageOpen
          setEditPageDetailOpen={setOpen}
          keywords={appContext.keywords}
          title={appContext.title}
          description={appContext.description}
          pageId={appContext.id}
          setMessage={setSnackMessage}
          setSnackbarOpen={setSnackbarOpen}
          setSeverity={setSnackSeverity}
          />
          )
        }

          <Footer
          scollTop={scrollTop}
          isIntroVideoComplete={isIntroVideoComplete}
          indexPage={indexPage}
          setEditPageDetailOpen={setOpen}
          />

          <NiwSnackbar {...snackBarProps} />
          </div>
          </SnackbarContext.Provider>
          )
        };


        export default Layout;
